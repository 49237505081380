import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  flex-shrink: 0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      Powered by FinMitr Academy of Training
    </FooterContainer>
  );
};

export default Footer;