import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { format } from 'date-fns';
import Loading from './Loading';
import NotFound from './NotFound';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const Header = styled(Navbar)`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
`;

const CertificateDetails = styled(Card)`
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
`;

const ContainerView = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5;
  flex: 1;
`;

const CertificateInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const InfoTitle = styled.div`
  font-weight: bold;
  color: #333;
`;

const InfoText = styled.div`
  color: #666;
`;

const CopyButton = styled(Button)`
  background-color: #213AFF;
  border: none;
  &:hover {
    background-color: #002BFF;
  }
`;

const LinkedInButton = styled(Button)`
  background-color: #0077b5;
  border: none;
  margin-left: 10px;
  &:hover {
    background-color: #005582;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  flex-shrink: 0;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: #000;  // Adjust the color as needed
`;

const SectionCard = styled(Card)`
  flex: 1;
  margin: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  text-align: center;
`;

const HorizontalSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ParentCard = styled(Card)`
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const RecommendedBox = styled.div`
  background-color: #e0ffe0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  color: #008000;
  margin-top: 10px;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
`;

const LearnerInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: bold;
`;

const VerifyPage = () => {
  const { id } = useParams();
  const toast = useRef(null);
  const [certificateData, setCertificateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const docRef = doc(db, 'certificates', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          data.issuedDate = format(data.issuedDate.toDate(), 'PPPP'); // Format the timestamp
          setCertificateData(data);
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [id]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.current.show({ severity: 'success', summary: 'Link Copied', detail: 'Certificate link copied to clipboard', life: 3000 });
  };

  const handleAddToLinkedIn = () => {
    const issuedDate = new Date(certificateData.issuedDate);
    const issueYear = issuedDate.getFullYear();
    const issueMonth = issuedDate.getMonth() + 1; // JavaScript months are 0-11
    const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Internship Certificate&organizationId=103740201&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${window.location.href}`;
    window.open(url, '_blank');
  };

  if (loading) {
    return <Loading />;
  }

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <Container>
          <Navbar.Brand href="#home" className="d-flex align-items-center">
            <img
              src={require('../assets/logo.svg').default}
              height="30"
              className="d-inline-block align-top"
              alt="FinMitr logo"
            />
            <LogoText>FinMitr</LogoText>
          </Navbar.Brand>
        </Container>
      </Header>

      <ContainerView>
        <Toast ref={toast} />

        <ParentCard>
          <HorizontalSection>
            <SectionCard>
              <div style={{ textAlign: 'left', marginBottom: '10px', fontWeight: 'bold' }}>This certificate was issued to</div>
              <LearnerInfo>
                <ProfileImage src={certificateData.learnerProfileImage} alt="Learner Profile" />
                <div>{certificateData.learnerName}</div>
              </LearnerInfo>
              {certificateData.recommended && (
                <RecommendedBox>Recommended</RecommendedBox>
              )}
            </SectionCard>

            <SectionCard>
              <div style={{ fontWeight: 'bold' }}>What {certificateData.learnerName} Learned</div>
              <ul style={{ textAlign: 'left' }}>
                {certificateData.whatYouLearned.map((item, index) => (
                  <li key={index} style={{ color: '#666', marginBottom: '5px' }}>{item}</li>
                ))}
              </ul>
            </SectionCard>
          </HorizontalSection>
        </ParentCard>

        <Row style={{ marginTop: '20px' }}>
          <Col md={8}>
            <img src={certificateData.certificateUrl} alt="Certificate" width="100%" />
          </Col>
          <Col md={4}>
            <CertificateDetails>
              <CertificateInfo>
                <InfoTitle>Learner Name:</InfoTitle>
                <InfoText>{certificateData.learnerName}</InfoText>
              </CertificateInfo>
              <CertificateInfo>
                <InfoTitle>Internship Role:</InfoTitle>
                <InfoText>{certificateData.internshipRole}</InfoText>
              </CertificateInfo>
              <CertificateInfo>
                <InfoTitle>Certificate ID:</InfoTitle>
                <InfoText>{certificateData.certificateId}</InfoText>
              </CertificateInfo>
              <CertificateInfo>
                <InfoTitle>Issued on:</InfoTitle>
                <InfoText>{certificateData.issuedDate}</InfoText>
              </CertificateInfo>
              <CertificateInfo>
                <InfoTitle>Number of Internship Days:</InfoTitle>
                <InfoText>{certificateData.internshipDays}</InfoText>
              </CertificateInfo>
              <hr />
              <div className="text-center">
                <div>This certificate has been issued by</div>
                <div style={{ fontWeight: 'bold' }}>{certificateData.issuerName}</div>
                <div style={{ marginBottom: '10px' }}>{certificateData.issuerPosition}</div>
                <div>
                  <CopyButton onClick={handleCopyLink}>Copy link</CopyButton>
                  <LinkedInButton onClick={handleAddToLinkedIn}>Add to LinkedIn</LinkedInButton>
                </div>
              </div>
            </CertificateDetails>
          </Col>
        </Row>
      </ContainerView>

      <Footer>
        Powered by FinMitr Academy of Training
      </Footer>
    </div>
  );
};

export default VerifyPage;
