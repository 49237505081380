import React from 'react';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const NotFoundContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 40px;
`;

const HomeButton = styled(Button)`
  background-color: #213aff;
  border: none;
  &:hover {
    background-color: #002bff;
  }
`;

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  };

  return (
    <NotFoundContainer>
      <Title>404 - Page Not Found</Title>
      <Subtitle>Sorry, the page you are looking for does not exist.</Subtitle>
      <HomeButton onClick={handleHome}>Go to Home</HomeButton>
    </NotFoundContainer>
  );
};

export default PageNotFound;
