import React, { useState, useRef } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { Toast } from 'primereact/toast';
import Header from './Header';
import Footer from './Footer';
import './HomePage.css';

const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5;
  flex: 1;
`;

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
`;

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 5vh;
  padding-bottom: 5vh;
`;

const VerifyButton = styled(Button)`
  background-color: #213AFF;
  border: none;
  &:hover {
    background-color: #1a2fdd;
  }
`;

const HomePage = () => {
  const [certificateId, setCertificateId] = useState('');
  const navigate = useNavigate();
  const toast = useRef(null);

  const handleVerify = () => {
    if (certificateId.trim() === '') {
      toast.current.show({ severity: 'warn', summary: 'Validation Error', detail: 'Please enter a certificate ID', life: 3000 });
      return;
    }
    navigate(`/verify/${certificateId}`);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <HeroContainer>
        <Toast ref={toast} />
        <StyledDiv>
          <h1 className="mb-4">Verify Learner Certificate</h1>
          <p>Enter the learner's credential ID to verify the certificate</p>
          <StyledForm inline onSubmit={(e) => { e.preventDefault(); handleVerify(); }}>
            <Form.Control
              type="text"
              placeholder="Enter Certificate ID"
              value={certificateId}
              onChange={(e) => setCertificateId(e.target.value)}
              className="mr-sm-2"
              style={{ flex: 1 }}
            />
            <VerifyButton type="submit">
              Verify
            </VerifyButton>
          </StyledForm>
        </StyledDiv>
      </HeroContainer>
      <Footer />
    </div>
  );
};

export default HomePage;
