// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDHQmypPh2aLiZKieJntO2pbDaSuZrNQXg",
    authDomain: "finmitr-certificate.firebaseapp.com",
    projectId: "finmitr-certificate",
    storageBucket: "finmitr-certificate.appspot.com",
    messagingSenderId: "880387363993",
    appId: "1:880387363993:web:93ae77e1d2a7ab3bd05408"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
