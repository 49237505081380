import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

const HeaderContainer = styled(Navbar)`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: #000;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={require('../assets/logo.svg').default}
            height="30"
            className="d-inline-block align-top"
            alt="FinMitr logo"
          />
          <LogoText>FinMitr</LogoText>
        </Navbar.Brand>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
