import React from 'react';
import { Container } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const LoadingContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <ClipLoader size={50} color={"#ff6f00"} />
      <h2>Loading...</h2>
    </LoadingContainer>
  );
};

export default Loading;
